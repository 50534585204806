export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_LAUNCHPAD_OPTION_REQUEST =
	"CREATE_LAUNCHPAD_OPTION_REQUEST";
export const CREATE_LAUNCHPAD_OPTION_SUCCESS =
	"CREATE_LAUNCHPAD_OPTION_SUCCESS";
export const CREATE_LAUNCHPAD_OPTION_FAILURE =
	"CREATE_LAUNCHPAD_OPTION_FAILURE";

export const UPDATE_LAUNCHPAD_OPTION_REQUEST =
	"UPDATE_LAUNCHPAD_OPTION_REQUEST";
export const UPDATE_LAUNCHPAD_OPTION_SUCCESS =
	"UPDATE_LAUNCHPAD_OPTION_SUCCESS";
export const UPDATE_LAUNCHPAD_OPTION_FAILURE =
	"UPDATE_LAUNCHPAD_OPTION_FAILURE";

export const REMOVE_LAUNCHPAD_OPTION_REQUEST =
	"REMOVE_LAUNCHPAD_OPTION_REQUEST";
export const REMOVE_LAUNCHPAD_OPTION_SUCCESS =
	"REMOVE_LAUNCHPAD_OPTION_SUCCESS";
export const REMOVE_LAUNCHPAD_OPTION_FAILURE =
	"REMOVE_LAUNCHPAD_OPTION_FAILURE";

export const TOGGLE_LAUNCHPAD_OPTION_SUCCESS =
	"TOGGLE_LAUNCHPAD_OPTION_SUCCESS";
export const TOGGLE_LAUNCHPAD_OPTION_REQUEST =
	"TOGGLE_LAUNCHPAD_OPTION_REQUEST";
export const TOGGLE_LAUNCHPAD_OPTION_FAILURE =
	"TOGGLE_LAUNCHPAD_OPTION_FAILURE";
