
export const GET_ALL_TRANSACTIONS_REQUEST = "GET_ALL_TRANSACTIONS_REQUEST";
export const GET_ALL_TRANSACTIONS_SUCCESS = "GET_ALL_TRANSACTIONS_SUCCESS";
export const GET_ALL_TRANSACTIONS_FAILURE = "GET_ALL_TRANSACTIONS_FAILURE";

export const UPDATE_TRANSACTION_REQUEST = "UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAILURE = "UPDATE_TRANSACTION_FAILURE";

export const UPDATE_TRANSACTION_REQUEST_LYO = "UPDATE_TRANSACTION_REQUEST_LYO";
export const UPDATE_TRANSACTION_SUCCESS_LYO = "UPDATE_TRANSACTION_SUCCESS_LYO";
export const UPDATE_TRANSACTION_FAILURE_LYO = "UPDATE_TRANSACTION_FAILURE_LYO";

export const GET_ALL_CHAINS_DETAILS = "GET_ALL_CHAINS_DETAILS";
export const GET_ALL_CHAINS_SUCCESS = "GET_ALL_CHAINS_SUCCESS";
export const GET_ALL_CHAINS_FAILURE = "GET_ALL_CHAINS_FAILURE";

export const GET_ALL_CHAINS_DETAILS_LYO = "GET_ALL_CHAINS_DETAILS_LYO";
export const GET_ALL_CHAINS_SUCCESS_LYO = "GET_ALL_CHAINS_SUCCESS_LYO";
export const GET_ALL_CHAINS_FAILURE_LYO = "GET_ALL_CHAINS_FAILURE_LYO";

export const SET_BRIDGE_ADDRESS = 'SET_BRIDGE_ADDRESS';
export const SET_NAME = 'SET_NAME';
export const SET_CHAIN_ID = 'SET_CHAIN_ID';
export const SET_WS_URL = 'SET_WS_URL';
export const SET_ACTIVE = 'SET_ACTIVE';

export const UPDATE_CHAINS_REQUEST = "UPDATE_CHAINS_REQUEST";
export const UPDATE_CHAINS_SUCCESS = "UPDATE_CHAINS_SUCCESS";
export const UPDATE_CHAINS_FAILURE = "UPDATE_CHAINS_FAILURE";


export const CREATE_CHAINS_REQUEST = "CREATE_CHAINS_REQUEST";
export const CREATE_CHAINS_SUCCESS = "CREATE_CHAINS_SUCCESS";
export const CREATE_CHAINS_FAILURE = "CREATE_CHAINS_FAILURE";

export const REMOVE_CHAINS_REQUEST = "REMOVE_CHAINS_REQUEST";
export const REMOVE_CHAINS_SUCCESS = "REMOVE_CHAINS_SUCCESS";
export const REMOVE_CHAINS_FAILURE = "REMOVE_CHAINS_FAILURE";

export const UPDATE_CHAINS_REQUEST_LYO = "UPDATE_CHAINS_REQUEST_LYO";
export const UPDATE_CHAINS_SUCCESS_LYO = "UPDATE_CHAINS_SUCCESS_LYO";
export const UPDATE_CHAINS_FAILURE_LYO = "UPDATE_CHAINS_FAILURE_LYO";


export const CREATE_CHAINS_REQUEST_LYO = "CREATE_CHAINS_REQUEST_LYO";
export const CREATE_CHAINS_SUCCESS_LYO = "CREATE_CHAINS_SUCCESS_LYO";
export const CREATE_CHAINS_FAILURE_LYO = "CREATE_CHAINS_FAILURE_LYO";

export const REMOVE_CHAINS_REQUEST_LYO = "REMOVE_CHAINS_REQUEST_LYO";
export const REMOVE_CHAINS_SUCCESS_LYO = "REMOVE_CHAINS_SUCCESS_LYO";
export const REMOVE_CHAINS_FAILURE_LYO = "REMOVE_CHAINS_FAILURE_LYO";


export const GET_BRIDGE_CONFIG_REQUEST = "GET_BRIDGE_CONFIG_REQUEST";
export const GET_BRIDGE_CONFIG_SUCCESS = "GET_BRIDGE_CONFIG_SUCCESS";
export const GET_BRIDGE_CONFIG_FAILURE = "GET_BRIDGE_CONFIG_FAILURE";


export const GET_BRIDGE_CONFIG_REQUEST_LYO = "GET_BRIDGE_CONFIG_REQUEST_LYO";
export const GET_BRIDGE_CONFIG_SUCCESS_LYO = "GET_BRIDGE_CONFIG_SUCCESS_LYO";
export const GET_BRIDGE_CONFIG_FAILURE_LYO = "GET_BRIDGE_CONFIG_FAILURE_LYO";

export const REJECT_TRANSACTION_REQUEST = "REJECT_TRANSACTION_REQUEST";
export const REJECT_TRANSACTION_SUCCESS = "REJECT_TRANSACTION_SUCCESS";
export const REJECT_TRANSACTION_FAILURE = "REJECT_TRANSACTION_FAILURE";

export const REJECT_TRANSACTION_REQUEST_LYO = "REJECT_TRANSACTION_REQUEST_LYO";
export const REJECT_TRANSACTION_SUCCESS_LYO = "REJECT_TRANSACTION_SUCCESS_LYO";
export const REJECT_TRANSACTION_FAILURE_LYO = "REJECT_TRANSACTION_FAILURE_LYO";

export const GET_TRANSACTION_STATS_REQUEST = "GET_TRANSACTION_STATS_REQUEST";
export const GET_TRANSACTION_STATS_SUCCESS = "GET_TRANSACTION_STATS_SUCCESS";
export const GET_TRANSACTION_STATS_FAILURE = "GET_TRANSACTION_STATS_FAILURE";

export const VOLUME_INFO_REQUEST = "VOLUME_INFO_REQUESTREQUEST";
export const VOLUME_INFO_SUCCESS = "VOLUME_INFO_SUCCESS_SUCCESS";
export const VOLUME_INFO_FAILURE = "VOLUME_INFO_FAILUREFAILURE";

export const GET_GATEWAY_BALANCE_REQUEST = "GET_GATEWAY_BALANCE_REQUEST";
export const GET_GATEWAY_BALANCE_SUCCESS = "GET_GATEWAY_BALANCE_SUCCESS";
export const GET_GATEWAY_BALANCE_FAILURE = "GET_GATEWAY_BALANCE_FAILURE";

export const CREATE_TRANSACTION_REQUEST = "CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILURE = "CREATE_TRANSACTION_FAILURE";